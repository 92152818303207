/* It is a self-invoking function in JavaScript. It is immediately invoked when the script is
loaded. This pattern is often used to create a private scope for variables and functions to avoid polluting the global scope. */
(function init_0() {
  if (window.jQuery)
    // jQuery is loaded
    contactFormSubmission();
  else {
    // jQuery is not loaded
  }
})();

/**
 * The function handles form submission using AJAX to send data to a specified
 * URL and display status messages accordingly.
 * @returns The `contactFormSubmission` function does not explicitly return any value.
 */
function contactFormSubmission() {
  const contactForm = $("form.cl-contact-form"); // contact form
  if (!contactForm.length) {
    /* this is a fail-safe */
    statusDiv.html("<span class=text-danger>Please fill the form carefully.</span>").fadeIn();
    return;
  }

  /* form submit method */
  contactForm.on("submit", function (e) {
    e.preventDefault(); // prevent default form submit
    const statusDiv = $(".status-msg"); // statusDiv div for show statusDiv message
    const submit = $(".cl-contact-form-submit"); // submit button

    $.ajax({
      // url: "https://locate365.in/firemail.php", // form action url
      url: "./firemail.php", // form action url
      type: "POST", // form submit method get/post
      dataType: "html", // request type html/json/xml
      data: contactForm.serialize(), // serialize form data
      beforeSend: function () {
        statusDiv.fadeOut(); // fade out any previous messages
        submit.text("Sending...."); // change submit button text
        submit.prop("disabled", true); // disable submit button to prevent multiple submissions
      },
      success: function (data) {
        statusDiv.html(data).fadeIn(); // fade in response data
        contactForm.trigger("reset"); // reset form
        submit.text("Sent").prop("disabled", true); // change submit button text and disable it
      },
      error: function (e) {
        statusDiv
          .html(
            `<p class="text-danger m-0">Some error occurred while sending the message.<br />Please drop us a message on our contact number.</p>`
          )
          .fadeIn();
        submit.text("Submit").prop("disabled", false); // reset submit button text and enable it
        console.log(e);
      },
    });
  });
}
